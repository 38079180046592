@font-face {
  font-family: EuclidSemiBold;
  src: url('./EuclidCircularASemibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidMedium;
  src: url('./EuclidCircularAMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidRegular;
  src: url('./EuclidCircularARegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidLight;
  src: url('./EuclidCircularALight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarLight;
  src: url('./Qatar/Qatar2022-Thin.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarRegular;
  src: url('./Qatar/Qatar2022-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarBold;
  src: url('./Qatar/Qatar2022-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PoppinsRegular;
  src: url('./Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PoppinsBold;
  src: url('./Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
