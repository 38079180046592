@font-face {
  font-family: EuclidSemiBold;
  src: url(/static/media/EuclidCircularASemibold.4ffb8f8b.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidMedium;
  src: url(/static/media/EuclidCircularAMedium.f27978eb.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidRegular;
  src: url(/static/media/EuclidCircularARegular.e191fa05.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EuclidLight;
  src: url(/static/media/EuclidCircularALight.54330d70.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarLight;
  src: url(/static/media/Qatar2022-Thin.19f34acf.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarRegular;
  src: url(/static/media/Qatar2022-Medium.32f6f6fc.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: QatarBold;
  src: url(/static/media/Qatar2022-Bold.9f425935.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PoppinsRegular;
  src: url(/static/media/Poppins-Regular.093ee89b.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PoppinsBold;
  src: url(/static/media/Poppins-Bold.08c20a48.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

